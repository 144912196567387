
:root {
  --a11y-hue-rotation: 0deg;
}

.a11y-hue {
  filter: hue-rotation(var(--a11y-hue-rotation));
}

$primary: #B90A3F; //new  #E00F4D //old #b3002f
$secondary: #000;
$light:#fff;
$nav-link-color: #000;
$nav-link-hover-color: #000;
$link-color: #000;
$link-hover-color: #000;
$link-hover-decoration: none;
$focus-ring-color:#ffdd00;
$focus-ring-width: 3px;
$focus-ring-box-shadow:#ffdd00;




