$body-font-family-default: "ProximaNova", sans-serif;
$title-font-family-default: "ProximaNova", sans-serif;

@mixin create-theme($name, $config) {
  .theme.#{$name} {
    --background-color: #{map-get($config, "background-color")};
    --on-background-color: #{map-get($config, "on-background-color")};
    --surface-color: #{map-get($config, "surface-color")};
    --on-surface-color: #{map-get($config, "on-surface-color")};
    --primary-color: #{map-get($config, "primary-color")};
    --on-primary-color: #{map-get($config, "on-primary-color")};
    --primary-color-hover: #{map-get($config, "primary-color-hover")};
    --secondary-color: #{map-get($config, "secondary-color")};
    --on-secondary-color: #{map-get($config, "on-secondary-color")};
    --focus-color: #{map-get($config, "focus-color")};
    --fab-color: #{map-get($config, "fab-color")};
    --on-fab-color: #{map-get($config, "on-fab-color")};

    @if map-has-key($config, "body-font-family") {
      --body-font-family-default: #{map-get($config, "body-font-family")};
    } @else {
      --body-font-family-default: #{$body-font-family-default};
    }

    @if map-has-key($config, "title-font-family") {
      --title-font-family-default: #{map-get($config, "title-font-family")};
    } @else {
      --title-font-family-default: #{$title-font-family-default};
    }

    --body-font-family: var(--body-font-family-default);
    --title-font-family: var(--title-font-family-default);

    font-family: var(--body-font-family);
  }
}
