$h1-font-size: 1.5rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1rem;
$h6-font-size: 1.1rem;

$nav-link-focus-box-shadow: none;
$focus-ring-box-shadow: #ffdd00;

@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap");

@font-face {
  font-family: ProximaNova;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/ProximaNova/ProximaNova-Regular.otf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: ProximaNova;
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/ProximaNova/ProximaNova-Bold.otf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/OpenDyslexic/OpenDyslexic-Regular.otf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/OpenDyslexic/OpenDyslexic-Bold.otf")
    format("opentype");
  font-display: swap;
}
