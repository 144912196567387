/* toastr-custom.css */

.toast{
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.toast-title{
  color:#000;
}

.toast-message{
  text-align: left;
  color: #000;
  font-size: 1rem;
  line-height: 1.3;
  flex: 1; /* Allow the message to take up remaining space */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) for long messages */
}

/* Customizing the toastr container */
.toast-container {
  position: fixed;
  z-index: 9999;
  top: 500px;
  right: 100px;
}

.toast-container .ngx-toastr{
  padding:10px;
}

/* Customizing toastr notifications */
.toast-success {
  /* Customize the success notification */
  background-image: none;
  background-color: #fff;
  border:2px solid#28a745;
}

.toast-success::before{
  float:left;
  color:#28a745;
  content: "\e876";
  font-family: "Material Icons";
  font-size:24px;
  font-weight:bold;
  margin-right:10px;
}

.toast-error {
  /* Customize the error notification */
  background-color: #fff;
  border:2px solid#dc3545;
}

.toast-error::before{
  float:left;
  color:#dc3545;
  content: "\e5cd";
  font-family: "Material Icons";
  font-size:24px;
  font-weight:bold;
  margin-right:10px;
}

.toast-info {
  /* Customize the info notification */
  background-color: #17a2b8;
  color: #fff;
}

.toast-warning {
  /* Customize the warning notification */
  background-color: #ffc107;
  color: #212529;
}



