$form-label-margin-bottom: 10px;
$input-border-width: 2px;
$input-border-color: #8c8c8c;
$input-focus-border-color: #ffdd00;
$input-focus-box-shadow: none;
$input-group-addon-bg: #000;
$input-group-addon-color: #fff;
$input-group-addon-border-color: #8c8c8c;
$input-border-radius: 0px;
$input-padding-y: 13px;
$input-padding-x: 13px;

input.ea-input[type="text"],
input.ea-input[type="password"],
input.ea-input[type="email"],
input.ea-input[type="number"],
input.ea-input[type="search"],
input.ea-input:not([multiple]),
select.ea-input[type="text"],
select.ea-input[type="password"],
select.ea-input[type="email"],
select.ea-input[type="number"],
select.ea-input[type="search"],
select.ea-input:not([multiple]) {
  display: block;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--surface-color);
  padding: 0.75rem 1rem;
  border-width: 1px;
  border-color: #8c8c8c;
  font-size: 1rem;
  border-radius: 5px;
}

input.ea-input[type="text"]:focus,
input.ea-input[type="password"]:focus,
input.ea-input[type="email"]:focus,
input.ea-input[type="number"]:focus,
input.ea-input[type="search"]:focus,
input.ea-input:not([multiple]):focus,
select.ea-input[type="text"]:focus,
select.ea-input[type="password"]:focus,
select.ea-input[type="email"]:focus,
select.ea-input[type="number"]:focus,
select.ea-input[type="search"]:focus,
select.ea-input:not([multiple]):focus {
  outline: var(--focus-color) solid 0.25rem;
}

input.ea-input[type="text"].ng-touched.ng-invalid,
input.ea-input[type="password"].ng-touched.ng-invalid,
input.ea-input[type="email"].ng-touched.ng-invalid,
input.ea-input[type="number"].ng-touched.ng-invalid,
input.ea-input[type="search"].ng-touched.ng-invalid,
input.ea-input:not([multiple]).ng-touched.ng-invalid,
select.ea-input[type="text"].ng-touched.ng-invalid,
select.ea-input[type="password"].ng-touched.ng-invalid,
select.ea-input[type="email"].ng-touched.ng-invalid,
select.ea-input[type="number"].ng-touched.ng-invalid,
select.ea-input[type="search"].ng-touched.ng-invalid,
select.ea-input:not([multiple]).ng-touched.ng-invalid {
  border-color: #9F0710;
  border-width: 2px;
}

select.ea-input {
  background-image: url(../assets/images/icons/chevron_down.svg);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 4rem;
}

select.ea-input option {
  color: var(--on-surface-color);
  background-color: var(--surface-color);
}

input[type="range"].ea-slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  background: var(--background-color);
}

input[type="range"].ea-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--primary-color);
  height: 1rem;
  width: 0.5rem;
}

input[type="range"].ea-slider::-moz-range-thumb {
  background: var(--primary-color);
  height: 1rem;
  width: 0.5rem;
}

input[type="range"].ea-slider::-ms-thumb {
  background: var(--primary-color);
  height: 1rem;
  width: 0.5rem;
}

input[type="range"].ea-slider::-ms-track {
  width: 100%;
  border-color: transparent;
  color: transparent;
  background: var(--background-color);
}
