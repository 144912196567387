//basic
.ea-button {
  box-sizing: border-box;
  border-radius: 9999px;
  border: 1px solid black;
  display: inline-grid;
  gap: 0.25rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  min-height: 2.5rem;
  padding: 0 1rem;
  line-height: 1;
  //white-space: nowrap;
  font-family: var(--body-font-family);
  cursor: pointer;
  min-width: fit-content;

  &:hover {
    border-color: #b3002f;
  }
}

//primary
.ea-button-primary-inactive{
  background-color: #F1F1F2 !important;
  color: #686464 !important;
  border: #5B5858 !important 1px;
}
.ea-button.ea-button-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--on-primary-color);
  font-size: 18px;
}
.ea-button.ea-button-primary:hover {
  border-color: var(--primary-color-hover);
  background-color: var(--primary-color-hover);
}

.ea-button.ea-button-primary:disabled {
  cursor: not-allowed;
  background-color: #f1f1f2;
  color: #686464;
  border: 1px solid #5b5858;
}

.ea-button.ea-button-primary:focus {
  outline: var(--focus-color) solid 0.25rem;
}

.ea-button.ea-button-primary:active {
  filter: brightness(0.9);
}

//secondary
.ea-button.ea-button-secondary {
  background-color: var(--secondary-color);
  color: var(--on-secondary-color);
}
.ea-button.ea-button-secondary {
}
.ea-button.ea-button-secondary:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.ea-button.ea-button-secondary:focus {
  outline: var(--focus-color) solid 0.25rem;
}

.ea-button.ea-button-secondary:active {
  filter: brightness(0.9);
}

//stroked
.ea-button.ea-button-stroked {
  border-width: 1px;
  border-color: var(--secondary-color);
  background-color: transparent;
  color: var(--secondary-color);
}

.ea-button.ea-button-stroked:hover {
  border-color: var(--primary-color);
}

.ea-button.ea-button-stroked:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.ea-button.ea-button-stroked:focus {
  outline: var(--focus-color) solid 0.25rem;
}

.ea-button.ea-button-stroked:active {
  filter: brightness(0.9);
}

//done

.ea-button.ea-button-done {
  background-color: #2e844a;
  border-color: #2e844a;
  color: white;
}
