@import "styles/colors.scss";
@import "styles/buttons.scss";
@import "styles/forms.scss";
@import "styles/toastr.scss";
@import "styles/fonts.scss";
@import "styles/cards.scss";
@import "styles/themes/themes.scss";
// @import "styles/mixins/theme.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@import "https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap";
*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

body,
html {
  font-family:
    ProximaNova,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Segoe UI Symbol,
    "Noto Color Emoji";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font-family);
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  color: #000;
  font-family: var(--title-font-family);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  font-family: var(--title-font-family);
}

span {
  font-family: var(--title-font-family);
}

.card {
  background: var(--on-background-color);
}

.focus-outline:focus {
  outline-color: theme("colors.theme.focus");
  outline-style: solid;
  outline-width: 0.25rem;
}

/*** CUSTOM COLORS ***/
.bg-light-gray {
  background: #e4e4e4;
}

.bg-medium-gray {
  background: #d2d2d2;
}

.bg-black {
  background: #000;
}

.bg-white {
  background: #fff;
}

.bg-none {
  background: none;
}

/*** END CUSTOM COLORS ***/

/*** COLORS ***/
.text-white {
  color: #fff;
}

.text-red {
  color: var(--primary-color);
}

.text-medium-gray {
  color: #8c8c8c;
}

.text-light-gray {
  color: #a8a7a7;
}
/*** END COLORS ***/

/*** WEIGHT ***/
.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

/*** END WEIGHT ***/

/*** BUTTONS ***/

button.noStyles {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
}

/*** END BUTTONS ***/

/*** BORDERS ***/
.border-radius-100 {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

.border-none {
  border: 0;
}

.border-gray-2px {
  border: 2px solid #e4e4e4;
}

.border-top-gray-2px {
  border-top: 2px solid #e4e4e4;
}

.border-right-gray-2px {
  border-right: 2px solid #e4e4e4;
}

.border-bottom-gray-2px {
  border-bottom: 2px solid #e4e4e4;
}

.border-left-gray-2px {
  border-left: 2px solid #e4e4e4;
}

.border-dark-gray-1px {
  border: 1px solid #b9b8b8;
}

.border-darker-gray-2px {
  border: 2px solid #d1d5db;
}

.border-top-darker-1px {
  border-top: 1px solid #b9b8b8;
}

.border-right-darker-1px {
  border-right: 1px solid #b9b8b8;
}

.border-bottom-darker-1px {
  border-bottom: 1px solid #b9b8b8;
}

.border-left-darker-1px {
  border-left: 1px solid #b9b8b8;
}

.border-gray-4px {
  border: 4px solid #8c8c8c;
}

.border-top-black-4px {
  border-top: 4px solid #000;
}

.border-top-gray-1px {
  border-top: 1px solid #8c8c8c;
}

/*** END BORDERS ***/

/*** SIZING ***/
.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-88 {
  width: 88%;
}

.w-100 {
  width: 100%;
}
.w-175 {
  width: 150%;
}
/*** END SIZING ***/

/*** POSITIONING ***/
.float-left {
  float: left;
}

.float-right {
  float: right;
}

/*** END POSITIONING ***/

/*** A11y ***/

// A11y FAB THEMES
.bg-theme-fab {
  background-color: var(--fab-color);
}

.text-theme-on-background {
  color: var(--on-background-color);
}

.text-theme-on-fab {
  color: var(--on-fab-color);
}

.text-theme-on-secondary {
  color: var(--on-secondary-color);
}

.text-theme-on-surface {
  color: var(--on-surface-color);
}

.text-theme-primary {
  color: var(--primary-color);
}

.text-theme-secondary {
  color: var(--secondary-color);
}

.font-title {
  font-family: var(--title-font-family);
}

.font-body {
  font-family: var(--body-font-family);
  height: 180px;
}

//END A11y FAB THEME

// A11y
.bottom-4 {
  bottom: 1rem;
}

.fixed {
  position: fixed;
}

.md\:right-8 {
  right: 0rem;
}

.z-20 {
  z-index: 20;
}

.rounded-full {
  border-radius: 9999px;
}

dialog[eaDrawer]::backdrop {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);
}

dialog::-webkit-backdrop {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #00000080;
}

dialog::backdrop {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #00000080;
}

/*** END A11y ***/

/*** MATERIAL ICONS ***/
span:focus-visible,
.material-icons:focus-visible {
  outline: none;
}

.material-icons {
  vertical-align: top;
  font-size: 25px;
}

/*** END MATERIAL ICONS ***/

/*** FORMS ***/
/*forms*/
.ea-label.required:after {
  content: " (Required)";
  color: #b3002f;
  font-weight: normal;
}

form {
  padding: 40px;
  background: #fff;
  border: none;
  font-family: var(--title-font-family);
}

.form-check-input {
  border: 1px solid #afafaf;
}

.form-control-flex {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

input.ea-input.form-control {
  color: var(--on-surface-color);
  background-color: var(--surface-color);
}

/*** END FORMS ***/

/*** GLOBAL MEDIA QUERIES ***/

/*** Small ***/
@media (min-width: 406px) and (max-width: 576px) {
  .container {
    max-width: 701px !important;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 700px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1200px !important;
  }
}

@media (min-width: 992px) {
  #top {
    .lg\:block {
      display: block;
    }
  }
  .container {
    max-width: 1115px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px !important;
  }
  /*** COPIED FROM OLD ***/
  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1241px !important;
  }
}

/*** END GLOBAL MEDIA QUERIES ***/

/*** GLOBAL MOBILE MEDIA QUERIES ***/
// /*** Galaxy ***/
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
  .bottom-4 {
    bottom: 5rem;
  }
}

// /*** Pixel 4 ***/
@media only screen and (-webkit-min-device-pixel-ratio: 2.75) {
}

// /*** Surface Duo ***/
@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
}

/*** END GLOBAL MOBILE MEDIA QUERIES***/

/****************************************************************************/
/*** MEDIA QUERY REFERENCE ***/

/*** Small ***/
// @media (min-width: 0px) and (max-width:406px) {body{background:red!important;}}
// @media (min-width: 406px) and (max-width:576px) {body{background:lime!important;}}
// @media (min-width: 576px) {body{background:pink!important;}}

// /*** More specific to desktop small to medium ***/
// @media (min-width: 576px) and (max-width:600px) {body{background:brown!important;}}

// /*** Medium ***/
// @media (min-width: 768px) {body{background:yellow!important;}}

// /*** Large ***/
// @media (min-width: 992px) {body{background:orange!important;}}

// /*** Extra Large ***/
// @media (min-width: 1273px) and (max-width:1300px) {body{background:lime!important;}}
// @media (min-width: 1273px) {body{background:lime!important;}}
// @media (min-width: 1200px) {body{background:red!important;}}
// @media (min-width: 1300px) and (max-width:1400px) {body{background:red!important;}}
// @media (min-width: 1400px) {body{background:blue!important;}}

// /*** Galaxy ***/
// @media only screen and (-webkit-min-device-pixel-ratio: 4) {body{background:purple!important;}}
// /*** Pixel 4 ***/
// @media only screen and (-webkit-min-device-pixel-ratio: 2.75) { body{background:steelblue;}}

// /*** Surface Duo ***/
// @media only screen and (-webkit-min-device-pixel-ratio: 2.5) {body{background:hotpink;}}

// /*** Iphone 6,7,8 ***/
// @media only screen and (min-device-width : 375px) and (max-device-width:500px){body{background:aqua;}}

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
.page {
  @apply max-w-screen-xl mx-auto px-4 md:px-8 py-16;
}

.focus-outline:focus {
  outline-color: theme("colors.theme.focus");
  outline-style: solid;
  outline-width: 0.25rem;
}

dialog::backdrop {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);
}

address {
  font-style: normal;
}

@import "https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap";
*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

:before,
:after {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Segoe UI Symbol,
    "Noto Color Emoji";
}

body {
  margin: 0;
  line-height: inherit;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

// .bottom-4 {
//   bottom: 1rem
// }

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.z-20 {
  z-index: 20;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-\[2px\] {
  margin-bottom: 2px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1/1;
}

.aspect-video {
  aspect-ratio: 16/9;
}

.aspect-\[4\/5\] {
  aspect-ratio: 4/5;
}

.h-full {
  height: 100%;
}

.h-16 {
  height: 4rem;
}

.h-1\/2 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-0 {
  height: 0px;
}

.h-auto {
  height: auto;
}

.h-1 {
  height: 0.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.h-24 {
  height: 6rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-7 {
  height: 1.75rem;
}

.h-40 {
  height: 10rem;
}

.h-96 {
  height: 24rem;
}

.h-64 {
  height: 16rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[6px\] {
  height: 6px;
}

.h-2 {
  height: 0.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.w-16 {
  width: 4rem;
}

.w-1\/2 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-32 {
  width: 8rem;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-12 {
  width: 3rem;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-2 {
  width: 0.5rem;
}

.w-\[2px\] {
  width: 2px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-28 {
  column-gap: 7rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-x-44 {
  column-gap: 11rem;
}

.gap-x-48 {
  column-gap: 12rem;
}

.gap-x-60 {
  column-gap: 15rem;
}

.gap-x-64 {
  column-gap: 16rem;
}

.gap-x-80 {
  column-gap: 20rem;
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-28 {
  row-gap: 7rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-y-44 {
  row-gap: 11rem;
}

.gap-y-48 {
  row-gap: 12rem;
}

.gap-y-60 {
  row-gap: 15rem;
}

.gap-y-64 {
  row-gap: 16rem;
}

.gap-y-80 {
  row-gap: 20rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-theme-secondary {
  border-color: var(--secondary-color);
}

.border-\[\#8c8c8c\] {
  --tw-border-opacity: 1;
  border-color: rgb(140 140 140 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-theme-on-background {
  border-color: var(--on-background-color);
}

.border-transparent {
  border-color: transparent;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-theme-focus {
  border-color: var(--focus-color);
}

.border-b-transparent {
  border-bottom-color: transparent;
}

.border-b-theme-secondary {
  border-bottom-color: var(--secondary-color);
}

.bg-theme-background {
  background-color: var(--background-color);
}

.bg-theme-fab {
  background-color: var(--fab-color);
}

.bg-theme-surface {
  background-color: var(--surface-color);
}

.bg-theme-secondary {
  background-color: var(--secondary-color);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-\[\#1D4289\] {
  --tw-bg-opacity: 1;
  background-color: rgb(29 66 137 / var(--tw-bg-opacity));
}

.bg-\[\#3FB9BE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 185 190 / var(--tw-bg-opacity));
}

.bg-\[\#9400BD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(148 0 189 / var(--tw-bg-opacity));
}

.bg-\[\#115F7E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 95 126 / var(--tw-bg-opacity));
}

.bg-\[\#B3002F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(179 0 47 / var(--tw-bg-opacity));
}

.bg-\[\#2554AD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 84 173 / var(--tw-bg-opacity));
}

.bg-\[\#F4F5F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity));
}

.bg-theme-on-background {
  background-color: var(--on-background-color);
}

.bg-theme-primary {
  background-color: var(--primary-color);
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-bottom {
  object-position: bottom;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-20 {
  padding: 5rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.font-title {
  font-family: var(--title-font-family);
}

.font-body {
  font-family: var(--body-font-family);
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-none {
  line-height: 1;
}

.leading-8 {
  line-height: 2rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-snug {
  line-height: 1.375;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.text-theme-on-background {
  color: var(--on-background-color);
}

.text-theme-on-fab {
  color: var(--on-fab-color);
}

.text-theme-on-secondary {
  color: var(--on-secondary-color);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-theme-on-surface {
  color: var(--on-surface-color);
}

.text-theme-primary {
  color: var(--primary-color);
}

.text-theme-secondary {
  color: var(--secondary-color);
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.a11y-font-size-md {
  font-size: 16px;
}

.a11y-font-size-lg {
  font-size: 18px;
}

.a11y-font-size-xl {
  font-size: 20px;
}

.a11y-font-size-2xl {
  font-size: 24px;
}

.a11y-font-size-3xl {
  font-size: 30px;
}

.a11y-font-size-4xl {
  font-size: 32px;
}

@font-face {
  font-family: ProximaNova;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/ProximaNova/ProximaNova-Regular.otf)
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: ProximaNova;
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/ProximaNova/ProximaNova-Bold.otf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/OpenDyslexic/OpenDyslexic-Regular.otf)
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/OpenDyslexic/OpenDyslexic-Bold.otf) format("opentype");
  font-display: swap;
}

//.theme.dark {
//  --background-color: #121212;
//  --on-background-color: #ffffff;
//  --surface-color: #000000;
//  --on-surface-color: #ffffff;
//  --primary-color: #b3002f;
//  --on-primary-color: #ffffff;
//  --secondary-color: #ffffff;
//  --on-secondary-color: #000000;
//  --focus-color: #ffdd00;
//  --fab-color: #146ff8;
//  --on-fab-color: #ffffff;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.dark-pd {
//  --background-color: #121212;
//  --on-background-color: #ffffff;
//  --surface-color: #000000;
//  --on-surface-color: #ffffff;
//  --primary-color: #d8ae26;
//  --on-primary-color: #000000;
//  --secondary-color: #ffffff;
//  --on-secondary-color: #000000;
//  --focus-color: #ffdd00;
//  --fab-color: #d8ae26;
//  --on-fab-color: #000000;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.dark-mono {
//  --background-color: #121212;
//  --on-background-color: #ffffff;
//  --surface-color: #000000;
//  --on-surface-color: #ffffff;
//  --primary-color: #ffffff;
//  --on-primary-color: #000000;
//  --secondary-color: #ffffff;
//  --on-secondary-color: #000000;
//  --focus-color: #146ff8;
//  --fab-color: #ffffff;
//  --on-fab-color: #000000;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.dark-dimmed {
//  --background-color: #22272e;
//  --on-background-color: #ffffff;
//  --surface-color: #2d333b;
//  --on-surface-color: #ffffff;
//  --primary-color: #b3002f;
//  --on-primary-color: #ffffff;
//  --secondary-color: #ffffff;
//  --on-secondary-color: #000000;
//  --focus-color: #ffdd00;
//  --fab-color: #146ff8;
//  --on-fab-color: #ffffff;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.light {
//  --background-color: #e4e4e4;
//  --on-background-color: #000000;
//  --surface-color: #ffffff;
//  --on-surface-color: #000000;
//  --primary-color: #b3002f;
//  --on-primary-color: #ffffff;
//  --secondary-color: #000000;
//  --on-secondary-color: #ffffff;
//  --focus-color: #ffdd00;
//  --fab-color: #146ff8;
//  --on-fab-color: #ffffff;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.light-pd {
//  --background-color: #e4e4e4;
//  --on-background-color: #000000;
//  --surface-color: #ffffff;
//  --on-surface-color: #000000;
//  --primary-color: #146ff8;
//  --on-primary-color: #ffffff;
//  --secondary-color: #000000;
//  --on-secondary-color: #ffffff;
//  --focus-color: #ffdd00;
//  --fab-color: #146ff8;
//  --on-fab-color: #ffffff;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}
//
//.theme.light-mono {
//  --background-color: #e4e4e4;
//  --on-background-color: #000000;
//  --surface-color: #ffffff;
//  --on-surface-color: #000000;
//  --primary-color: #000000;
//  --on-primary-color: #ffffff;
//  --secondary-color: #000000;
//  --on-secondary-color: #ffffff;
//  --focus-color: #ffdd00;
//  --fab-color: #000000;
//  --on-fab-color: #ffffff;
//  --body-font-family-default: Roboto, sans-serif;
//  --title-font-family-default: ProximaNova, sans-serif;
//  --body-font-family: var(--body-font-family-default);
//  --title-font-family: var(--title-font-family-default);
//  font-family: var(--body-font-family);
//}

a[href].skip-link {
  top: 1rem;
  left: 1rem;
  //padding: 0.5rem;
}

a[href].skip-link:focus {
  height: auto;
  width: auto;
  clip: auto;
  outline-color: var(--focus-color);
  outline-style: solid;
  outline-width: 0.25rem;
}

.animated.fade-in {
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (prefer-reduced-motion: reduce) {
  .animated {
    animation: none !important;
    transition: none !important;
  }
}

.ea-form .ea-form-field {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.ea-form .ea-form-field .ea-label {
  font-size: 0.875rem;
}

.ea-form .ea-form-field .ea-label.required:after {
  content: " (Required)";
  color: #b3002f;
}
.highlight {
  outline: var(--focus-color) solid 0.25rem;
}

$types: "text", "password", "email", "number", "search";

input {
  &.ea-input {
    @each $type in $types {
      &[type="#{$type}"] {
        &:focus {
          @extend .highlight;
        }
      }
    }

    &:not([multiple]):focus {
      @extend .highlight;
    }
  }
}

select {
  &.ea-input {
    &:focus {
      @extend .highlight;
    }
  }
}

.skeleton.loading {
  background-image: linear-gradient(
    90deg,
    var(--background-color),
    var(--surface-color),
    var(--background-color)
  );
  animation-name: load;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
  animation-timing-function: linear;
  background-size: 200% 100%;
}

@keyframes load {
  0% {
    background-position: 100% 0%;
  }

  to {
    background-position: -100% 0%;
  }
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  left: 0;
}

[dir="rtl"] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 4rem 1rem;
}

@media (min-width: 768px) {
  .page {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.focus-outline:focus {
  outline-color: var(--focus-color);
  outline-style: solid;
  outline-width: 0.25rem;
}

dialog::-webkit-backdrop {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #00000080;
}

dialog::backdrop {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #00000080;
}

address {
  font-style: normal;
}

.last\:border-0:last-child {
  border-width: 0px;
}

.empty\:hidden:empty {
  display: none;
}

.dark .dark\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.dark .dark\:opacity-50 {
  opacity: 0.5;
}

@media (min-width: 640px) {
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:bottom-8 {
    bottom: 1rem;
  }

  // .md\:right-8 {
  //   right: 0rem;
  // }

  .md\:aspect-square {
    aspect-ratio: 1/1;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-\[40vw\] {
    max-width: 40vw;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0px;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }
}

@media (min-width: 1280px) {
  // .xl\:auto-cols-min {
  //     grid-auto-columns:min-content
  // }

  // .xl\:grid-flow-col {
  //     grid-auto-flow: column
  // }

  // .xl\:grid-cols-1 {
  //     grid-template-columns: repeat(1,minmax(0,1fr))
  // }

  // .xl\:grid-cols-2 {
  //     grid-template-columns: repeat(2,minmax(0,1fr))
  // }

  // .xl\:grid-cols-3 {
  //     grid-template-columns: repeat(3,minmax(0,1fr))
  // }

  // .xl\:grid-cols-4 {
  //     grid-template-columns: repeat(4,minmax(0,1fr))
  // }

  // .xl\:grid-cols-5 {
  //     grid-template-columns: repeat(5,minmax(0,1fr))
  // }

  // .xl\:grid-cols-6 {
  //     grid-template-columns: repeat(6,minmax(0,1fr))
  // }

  // .xl\:grid-cols-7 {
  //     grid-template-columns: repeat(7,minmax(0,1fr))
  // }

  // .xl\:grid-cols-8 {
  //     grid-template-columns: repeat(8,minmax(0,1fr))
  // }

  // .xl\:grid-cols-10 {
  //     grid-template-columns: repeat(10,minmax(0,1fr))
  // }

  // .xl\:grid-cols-11 {
  //     grid-template-columns: repeat(11,minmax(0,1fr))
  // }

  // .xl\:grid-cols-12 {
  //     grid-template-columns: repeat(12,minmax(0,1fr))
  // }

  // .xl\:gap-8 {
  //     gap: 2rem
  // }

  // .xl\:p-8 {
  //     padding: 2rem
  // }
}

// dialog {
//   position: absolute;
//   left: 0;
//   right: 0;
//   width: -moz-fit-content;
//   width: fit-content;
//   height: -moz-fit-content;
//   height: fit-content;
//   margin: auto;
//   border: solid;
//   padding: 1em;
//   background: white;
//   color: #000;
//   display: block
// }

// dialog:not([open]) {
//   display: none
// }

// dialog+.backdrop {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: rgba(0,0,0,.1)
// }

// ._dialog_overlay {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0
// }

// dialog.fixed {
//   position: fixed;
//   top: 50%;
//   transform: translateY(-50%)
// }
ea-login-by-sms {
  .iti__country-list,
  .iti--allow-dropdown input {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
  }
}

//Accessibility Tools - Hide Image
body.hide-image-mode {
  img {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  div,
  a {
    background-image: none !important;
  }
}

body.reading-mode {
  .force-hidden {
    display: none !important;
    visibility: hidden !important;
  }

  #page-wrapper .header-menu.elementor-sticky--active {
    margin-top: 60px !important;
  }

  .recite-word-by-word {
    padding: 0;
  }

  #page-wrapper {
    width: 50%;
    margin: auto;

    a {
      color: blue;
    }

    input,
    form,
    iframe,
    mat-icon {
      display: none;
    }

    div,
    a {
      background-image: none !important;
    }
  }
}
.iti {
  width: 100%;
}

ngx-intl-tel-input.ng-invalid .iti--allow-dropdown input[type="tel"] {
  border-color: #9f0710;
  border-width: 2px;
}

ngx-intl-tel-input .iti--allow-dropdown input[type="tel"] {
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  border-width: 1px;
  border-radius: 5px;
  width: 100%;
}

html,
body {
  height: 100%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: ProximaNova, "Helvetica Neue", sans-serif;
}

.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: transparent !important;
  transition: none !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--on-background-color) !important;
}

.div-active {
  background: #181618;
  color: #ffffff;
  p {
    color: #ffffff;
  }
}
.form-radio-input {
  width: 25px;
  height: 25px;
  border: 0.05px solid; /* Border color */
  border-radius: 50%; /* Makes the radio button round */
  appearance: none; /* Removes default browser styling */
  position: relative;
  cursor: pointer;
}

.form-radio-input:checked {
  background-color: white; /* Background color when checked */
  border-color: var(--primary-color); /* Ensures border color when checked */
}

.form-radio-input:checked::before {
  content: ""; /* Adds a pseudo-element for the inner circle */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  background-color: var(--primary-color); /* Inner circle color when checked */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
  background-color: var(--primary-color) !important;
}

.quantity-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 25px; /* Rounded pill shape */
  overflow: hidden;
  border: 0.0005px solid;
  width: fit-content;
  background-color: #e0e0e0;
  padding: 2px;
}

.quantity-button {
  background-color: #d1d1d1;
  border: none;
  padding: 5px 12px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.quantity-input {
  text-align: center;
  width: 60px; /* Smaller input width */
  border: none;
  font-size: 18px;
  margin: 0;
  height: 35px;
  outline: none;
}

.quantity-button:first-of-type {
  border-radius: 25px 0 0 25px; /* Left rounded corner */
}

.quantity-button:last-of-type {
  border-radius: 0 25px 25px 0; /* Right rounded corner */
}

.quantity-button:focus,
.quantity-input:focus {
  outline: none;
}
.quantity-col {
  button {
    width: 120px;
  }
}
.quantity-box {
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: column;
}
.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary-color);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-color);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--mat-select-enabled-arrow-color) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mat-select-enabled-arrow-color) !important;
}

@media (max-width: 576px) {
  .cc-revoke {
    display: none !important;
  }
}

.form-select {
  border-radius: 5px;
}
.input-group-text {
  border-radius: 5px;
}
.step-container {
  display: none;
}

.sticky-header {
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    width: 100%;
    min-width: 300px;
    background-color: #dcdbdb !important;
    box-shadow: 0 4px 2px -2px gray;
    z-index: 1000;
    height: 100px;
    ea-search-bar {
      display: none;
    }
    .total-cart-amount {
      display: block !important;
      font-size: 14px;
      font-weight: lighter;
    }
    .showTotal {
      .navbar-toggler-icon {
        display: none;
      }
      .icon-tag {
        display: none;
      }
    }
    .search-btn {
      display: flex !important;
    }
    .step-container {
      display: block;
    }
  }
}

.cc-revoke.cc-top {
  right: 3em;
  left: unset !important;
}
.ea-button {
  .step-icon {
    width: 40px;
  }
}

.like {
  color: #f21c5c; //ruby red, need add to scss color variables
  cursor: pointer;
}
